import { Box, Card, CardHeader, Divider } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Embed, models, service, IEmbedConfiguration, Page, Report } from "powerbi-client";
import {
  PowerBIEmbed,
  EventHandler,
  getEmbeddedComponent,
  cssClassName,
  changeSettings,
  EmbedProps,
} from "powerbi-client-react";
import { makeStyles } from "@mui/styles";
import { useReportConfig } from "ReportConfigContext";

const useStyles = makeStyles({
  pbireport: {
    //display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    margin: "0%",
    border: "none !important",
    "& iframe": {
      border: "none !important", // Remove border from iframe
    },
  },
  pbicontainer: {
    minHeight: "89vh",
    overflow: "hidden !important",
    height: "100%",
    width: "100%",
  },
});

export default function usePowerBiReport() {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug, pageId } = useParams();
  const [report, setReport] = useState();
  const [reportPageName, setReportPageName] = useState();
  const [changedPageName, setChangedPageName] = useState();
  const { reportConfig, setReportConfig } = useReportConfig();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userAzureData = userData.azure_functions ? userData.azure_functions : [];
  const [selectedReport, setSelectedReport] = useState(null);
  const [error, setError] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const isValueInAllowedFunctions = (value, arr) => {
    return arr.some((obj) => obj.report_slug === value);
  };
  const hasReportPermission = isValueInAllowedFunctions(slug, userAzureData);
  const [responseConfig, setResponseConfig] = useState({
    type: "report",
    id: undefined,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    pageName: undefined,
    settings: {
      filterPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    setPermissionError("");
    setError("");
    if (hasReportPermission == false) {
      setPermissionError("Opps! You do not have permission to view this report!");
    } else {
      const selectedPBReport = reportConfig.find((rconfig) => rconfig.report_slug === slug);
      if (selectedPBReport) {
        setResponseConfig((prevConfig) => ({
          ...prevConfig, // Preserve the existing configuration
          id: selectedPBReport.id,
          embedUrl: selectedPBReport.embedUrl,
          accessToken: selectedPBReport.accessToken,
        }));
        setSelectedReport(selectedPBReport);
        if (changedPageName !== pageId) {
          setReportPageName(pageId);
          setResponseConfig((prevConfig) => ({
            ...prevConfig, // Preserve the existing configuration
            pageName: pageId,
          }));
        }
      } else {
        setError("Opps! Something went wrong!");
      }
    }
  }, [slug, pageId, reportConfig]);
  const handleUpdateReportPages = (slugPages, activePage) => {
    let allPBReports = JSON.parse(sessionStorage.getItem("reportConfig")) || [];
    allPBReports = allPBReports.map((pbreport) => {
      if (pbreport.id === window.report.config.id) {
        return { ...pbreport, pages: slugPages };
      }
      return pbreport;
    });
    // Store the updated array back into sessionStorage
    setReportConfig(allPBReports);
    const activeReportWithPage = allPBReports.filter((pbreport) => {
      if (pbreport.id === window.report.config.id) {
        return pbreport;
      }
    });
    // when report get loaded make the default active page as active in sidenav
    if (activePage.length > 0) {
      // Construct the new path
      const newPath = `/${activeReportWithPage[0].report_slug}/${activePage.name}`;
      // Navigate to the new path
      if (location.pathname !== newPath) {
        navigate(newPath, { replace: true });
      }
    }
  };
  // get new access token when it is expired
  const fetchNewToken = async (embedReference) => {
    try {
      const response = await fetch(embedReference); // Your endpoint to get a new token
      const data = await response.json();
      setResponseConfig((prevConfig) => ({
        ...prevConfig, // Preserve the existing configuration
        id: data.id,
        embedUrl: data.embedUrl,
        accessToken: data.accessToken,
      }));
      return data.embedToken;
    } catch (error) {
      console.error("Error fetching new token:", error);
    }
  };
  //handle token expired event
  const handleTokenExpiration = async (report, functionReference) => {
    const newToken = await fetchNewToken(functionReference);
    if (newToken) {
      setReportConfig((reportConfig) =>
        reportConfig.map((pbreport) =>
          pbreport.id === report.config.id ? { ...pbreport, accessToken: newToken } : pbreport
        )
      );
    } else {
      console.error("Failed to get a new token");
    }
  };
  // Event handler for loaded report
  const eventHandlersMap = new Map([
    [
      "loaded",
      async function () {
        console.log("Report loaded..");
        //fetch pages for loaded report
        const pbireportPages = await window.report.getPages();
        //get default active page on report load
        const activePage = await window.report.getActivePage();
        //get pages for submenu using defaultDisplayOption=1 and visibility=0
        const mainMenu = pbireportPages.filter(
          (report) => report.defaultDisplayOption == 1 && report.visibility == 0
        );
        const selectedKeys = ["name", "displayName"];
        const PBReportPages = mainMenu.map((item) => {
          let PBPages = {};
          selectedKeys.forEach((key) => {
            if (item.hasOwnProperty(key)) {
              PBPages[key] = item[key];
            }
          });
          return PBPages;
        });
        handleUpdateReportPages(PBReportPages, activePage);
      },
    ],
    [
      "rendered",
      async function () {
        console.log("Report rendered");
      },
    ],
    [
      "error",
      function (event) {
        if (
          event.detail.message === "TokenExpired" ||
          event.detail.message === "LoadReportFailed"
        ) {
          const relatedReport = reportConfig.filter((pbreport) => {
            if (pbreport.id === window.report.config.id) {
              return pbreport;
            }
          });
          const relatedFunction = userAzureData.filter((azdata) => {
            if (azdata.report_slug === relatedReport[0].report_slug) {
              return azdata;
            }
          });
          handleTokenExpiration(window.report, relatedFunction[0].function_reference);
        } else if (event.detail.message === "Missing_References") {
          console.log("error event", event.detail);
        } else {
          console.log("error event", event.detail);
          //setError("Opps! Something went wrong!");
        }
      },
    ],
    [
      "pageChanged",
      function (event) {
        const pageName = event.detail.newPage.name;
        const pageDisplayName = event.detail.newPage.displayName;
        setChangedPageName(pageName);
        const pageChangeReportWithPage = reportConfig.filter((pbreport) => {
          if (pbreport.id === event.detail.newPage.report.config.id) {
            return pbreport;
          }
        });
        if (pageChangeReportWithPage.length > 0) {
          const activeReportMenu = [
            {
              reportDisplayName: `${pageChangeReportWithPage[0].function_name}`,
              pageDisplayName: `${pageDisplayName}`,
            },
          ];
          sessionStorage.setItem("activeReportMenu", JSON.stringify(activeReportMenu));
          // Construct the new path
          const newPath = `/${pageChangeReportWithPage[0].report_slug}/${pageName}`;
          // Navigate to the new path
          navigate(newPath, { replace: true });
        }
      },
    ],
  ]);

  const classes = useStyles();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card id="powerbi-report" className={classes.pbicontainer}>
          <MDBox>
            {permissionError && (
              <MDBox>
                <MDTypography display="block" variant="h6" color="error" m={2}>
                  {permissionError}
                </MDTypography>
              </MDBox>
            )}
            {error && (
              <MDBox>
                <MDTypography display="block" variant="h6" color="error" m={2}>
                  {error}{" "}
                  <MDTypography
                    component={Link}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    onClick={handleReload}
                    textGradient
                  >
                    Click here
                  </MDTypography>{" "}
                  to resend the request OR Please logout and try to sign in again after some time.
                </MDTypography>
              </MDBox>
            )}
            {!permissionError && !error && selectedReport && (
              <PowerBIEmbed
                embedConfig={responseConfig}
                eventHandlers={eventHandlersMap}
                cssClassName={classes.pbireport}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                  setReport(embeddedReport);
                }}
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
